import React, { useEffect } from "react";
import { UserForm, Sidebar } from "../../component";
import { observer } from "mobx-react";
import { usersStore, IUserForm, userFormStore, authStore } from "../../stores";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { Loading } from "../../component/loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type Props = {};

// Helper functions
export const formatDateToDDMMYYYY = (date: Date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const Users: React.FC<Props> = observer(() => {
  const navigate = useNavigate();

  const { isLogin } = authStore;

  useEffect(() => {
    if (isLogin) {
      usersStore.getUsers();
    } else {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  return (
    <div className="sb-nav-fixed relative">
      {usersStore.loadingUsers && <Loading />}

      <Sidebar />

      <div className="p-4 sm:ml-40">
        <div className="flex flex-wrap justify-between items-start">
          <div>
            <button
              type="button"
              onClick={() => {
                userFormStore.resetForm();
                userFormStore.setTypeOfForm("add");
              }}
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              Add New User
            </button>
          </div>
          <div className="mr-8">
            Total people confirmed: {usersStore.totalPeople}
          </div>
        </div>

        {userFormStore.typeOfForm && <UserForm />}

        <div className="relative overflow-x-auto mt-4">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-2 w-[5%]">
                  No
                </th>
                <th scope="col" className="px-6 py-3 w-[5%]">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 w-[5%]">
                  Group
                </th>
                <th scope="col" className="px-6 py-3 w-[40%]">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 w-[20%]">
                  Thank Letter
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Location
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Quantity Confirm
                </th>

                <th scope="col" className="px-6 py-3 w-2/12">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {usersStore.paginatedUsers.map((user, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">{user.name}</td>
                  <td className="px-6 py-4">{user.group}</td>
                  <td className="px-6 py-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: user.description || "",
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: user.thankLetter || "",
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">{user.location}</td>
                  <td className="px-6 py-4">
                    {user.peopleConfirm || "Not yet"}
                  </td>

                  <td className="px-6 py-4">
                    <span className="flex flex-wrap gap-1.5">
                      <button
                        type="button"
                        onClick={() => {
                          window.scroll(0, 0);
                          userFormStore.getDetailUser(user.slug || "");
                        }}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          Object.entries(user).forEach(([key, value]) => {
                            userFormStore.setField(
                              key as keyof IUserForm,
                              value as IUserForm[keyof IUserForm]
                            );
                          });
                          window.scroll(0, 0);
                          userFormStore.setTypeOfForm("delete");
                        }}
                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      >
                        Delete
                      </button>

                      <button
                        onClick={() => {
                          const textToCopy = `khanhlinh-vuhoc.io.vn/${user.slug}`;
                          navigator.clipboard
                            .writeText(textToCopy)
                            .then(() => {
                              const sweetAlert = withReactContent(Swal);
                              sweetAlert.fire({
                                title: `<p>${textToCopy} Copied!</p>`,
                                icon: "success",
                                timer: 800,
                                showConfirmButton: false,
                                toast: true,
                                position: "top",
                              });
                            })
                            .catch(() => {
                              const sweetAlert = withReactContent(Swal);
                              sweetAlert.fire({
                                title: "<p>Failed to copy</p>",
                                icon: "error",
                                timer: 1200,
                                showConfirmButton: false,
                                toast: true,
                                position: "top",
                              });
                            });
                        }}
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
                      >
                        Copy Link
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
