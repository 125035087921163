import "./App.css";
import { Login, Static, Users } from "./pages";
import { Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <Routes>
      {/* Redirect from root path "/" to "/users" */}
      <Route path="/" element={<Navigate to="/users" replace />} />

      <Route path="/login" element={<Login />} />

      <Route path="/users" element={<Users />} />

      {/* <Route path="/news" element={<News />} /> */}

      <Route path="/static" element={<Static />} />
    </Routes>
  );
}

export default App;
