// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* quillCustomStyles.css */
.custom-quill-thank .ql-container {
    height: 3rem
    /* Tailwind class for height */
}

.custom-quill .ql-editor {
    min-height: 100%
    /* Full height for the editor */
}`, "",{"version":3,"sources":["webpack://./src/component/editor-thank/quill-custom.style.css"],"names":[],"mappings":"AAAA,0BAA0B;AAEtB;IAAA,WAAW;IACX,8BAA8B;AADnB;;AAKX;IAAA,eAAiB;IACjB,+BAA+B;AADd","sourcesContent":["/* quillCustomStyles.css */\n.custom-quill-thank .ql-container {\n    @apply h-12;\n    /* Tailwind class for height */\n}\n\n.custom-quill .ql-editor {\n    @apply min-h-full;\n    /* Full height for the editor */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
