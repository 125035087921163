import { makeAutoObservable } from "mobx";
import { API, APIRoutes } from "../apis";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { usersStore } from "./users.store";

export interface IUserForm {
  id?: number;
  group?: string;
  name?: string;
  slug?: string;
  description?: string;
  thankLetter?: string;
  language?: string;
  location?: string;
  peopleConfirm?: number;
  createdDate?: string;
}

class UserFormStore {
  formData: IUserForm = {
    name: "",
    group: "",
    slug: "",
    location: "sg",
    language: "vi",
    description: "",
    thankLetter: "",
  };
  typeOfForm: "add" | "update" | "delete" | undefined = undefined;
  errorForm: string = "";
  loadingForm: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setField<K extends keyof IUserForm>(name: K, value: IUserForm[K]) {
    this.formData[name] = value;
  }

  setError(err: string) {
    this.errorForm = err;
  }

  setTypeOfForm(type: typeof this.typeOfForm) {
    this.typeOfForm = type;
  }

  resetForm() {
    this.formData = {
      name: "",
      group: "",
      slug: "",
      location: "sg",
      language: "vi",
      description: "",
      thankLetter: "",
    };
    this.typeOfForm = undefined;
  }

  async getDetailUser(slug: string) {
    this.loadingForm = true;

    try {
      const { data } = await API.get(APIRoutes.GET_USER_DETAIL(slug));

      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          this.setField(
            key as keyof IUserForm,
            value as IUserForm[keyof IUserForm]
          );
        });
      }

      console.log(this.formData);

      this.setTypeOfForm("update");
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingForm = false;
    }
  }

  async submitAddForm() {
    this.loadingForm = true;
    try {
      const response = await API.post(APIRoutes.CREATE_USER, this.formData);

      if (response.data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>User created successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.resetForm();
      usersStore.getUsers();
    } catch (error) {
      console.error("There was an error adding the user!", error);
    } finally {
      this.loadingForm = false;
    }
  }

  async updateUser() {
    this.loadingForm = true;
    try {
      const { data } = await API.put(
        APIRoutes.UPDATE_USER(this.formData.id || 0),
        this.formData
      );
      if (data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>User updated successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.resetForm();
      usersStore.getUsers();
    } catch (error) {
      console.error("There was an error updating the user!", error);
    } finally {
      this.loadingForm = false;
    }
  }

  async deleteUser() {
    this.loadingForm = true;
    try {
      const { data } = await API.delete(
        APIRoutes.DELETE_USER(this.formData.id || 0)
      );
      if (data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>User deleted successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.resetForm();
      usersStore.getUsers(); // Fetch updated users list
    } catch (error) {
      console.error("There was an error deleting the user!", error);
    } finally {
      this.loadingForm = false;
    }
  }
}

export const userFormStore = new UserFormStore();
