// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: rgba(227, 227, 227, 0.7);
}

.loading__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: rgba(227, 227, 227, 0.7);
  position: absolute;
  left: 50%;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/component/loading/loading.style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,oCAAoC;EACpC,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,gCAAgC;EAChC,WAAW;AACb","sourcesContent":[".loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 1rem;\n  background: rgba(227, 227, 227, 0.7);\n}\n\n.loading__overlay {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 1rem;\n  background: rgba(227, 227, 227, 0.7);\n  position: absolute;\n  left: 50%;\n  width: 100%;\n  height: 100%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
