import React from "react";
import { observer } from "mobx-react";
import { userFormStore } from "../../stores";
import "react-quill/dist/quill.snow.css";
import { Editor } from "../editor";
import { Loading } from "../loading";
import { EditorThank } from "../editor-thank";

type Props = {};

const placeholderText =
  "Gửi dì Châu thân yêu,\nChúng con vô cùng hạnh phúc khi được chia sẻ ngày đặc biệt nhất trong cuộc đời mình với dì. Mỗi bước trên hành trình của chúng con đều tràn ngập yêu thương, tiếng cười và những kỷ niệm không thể nào quên. Giờ đây, chúng con háo hức bắt đầu chương mới của cuộc đời, với những người thân yêu nhất ở bên cạnh!\nĐể chúng con có thể chuẩn bị chu đáo hơn, mong dì dành chút thời gian điền vào form xác nhận RSVP dưới đây. Sự hiện diện của dì là niềm vui lớn lao nhất đối với chúng con!";

const placeholderTextThank =
  "Cảm ơn dì đã dành thời gian xác nhận! Chúng con rất háo hức được gặp dì và cùng nhau chia sẻ niềm vui trong ngày đặc biệt này!";

export const UserForm: React.FC<Props> = observer(() => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    userFormStore.setField(name as keyof typeof userFormStore.formData, value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { slug, name, location } = userFormStore.formData;

    if (userFormStore.typeOfForm === "update") {
      userFormStore.updateUser();
    } else {
      if (slug === "" || name === "" || location === "") {
        return userFormStore.setError(
          "Please fill full fields, except description!"
        );
      }
      userFormStore.submitAddForm();
    }
  };

  return (
    <div className="my-3 relative">
      {userFormStore.loadingForm && <Loading />}
      <h1 className="text-center my-4">
        {userFormStore.typeOfForm?.toUpperCase()} USER
      </h1>
      {userFormStore.typeOfForm === "delete" ? (
        <div className="w-full border-2 p-6 rounded-md flex flex-wrap">
          <h2>
            Are you sure you want to delete the user:{" "}
            {userFormStore.formData.name}?
          </h2>
          <div className="w-full flex flex-wrap justify-between">
            <button
              type="button"
              onClick={() => {
                userFormStore.resetForm();
              }}
              className="w-[48%] text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              CANCEL
            </button>
            <button
              type="button"
              onClick={() => {
                userFormStore.deleteUser();
              }}
              className="w-[48%] text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {userFormStore.typeOfForm?.toUpperCase()}
            </button>
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="w-full border-2 p-6 rounded-md flex flex-wrap"
        >
          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="slug"
              id="slug"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
              value={userFormStore.formData.slug}
              onChange={handleChange}
              disabled={userFormStore.typeOfForm === "update"}
            />
            <label
              htmlFor="slug"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Slug (Ex: chi_tram_dep_gai) - Unique
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="name"
              id="name"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              value={userFormStore.formData.name}
              onChange={handleChange}
              placeholder=""
            />
            <label
              htmlFor="name"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              User Name (Ex: Chị Trâm Đẹp Gái)
            </label>
          </div>

          <div className="relative z-0 w-3/12 pr-8 mb-5 group text-left">
            <input
              type="text"
              name="group"
              id="group"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              value={userFormStore.formData.group}
              onChange={handleChange}
              placeholder=""
            />
            <label
              htmlFor="name"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Group (Ex: Dinosaur)
            </label>
          </div>

          <div className="relative z-0 w-3/12 pr-4 mb-5 group mt-6 text-left">
            <select
              name="location"
              id="location"
              className="block w-full px-2.5 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-600 transition duration-150 ease-in-out disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 peer"
              required
              value={userFormStore.formData.location}
              onChange={handleChange}
            >
              <option value="">Select location</option>
              {["sg", "hn"].map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <label
              htmlFor="location"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-8 top-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:top-2.5 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Location
            </label>
          </div>

          <div className="relative z-0 w-3/12 pr-4 mb-5 group mt-6 text-left">
            <select
              name="language"
              id="language"
              className="block w-full px-2.5 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-600 transition duration-150 ease-in-out disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 peer"
              required
              value={userFormStore.formData.language}
              onChange={handleChange}
            >
              <option value="">Select language</option>
              {["vi", "en"].map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <label
              htmlFor="language"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-8 top-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:top-2.5 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Language
            </label>
          </div>

          <div className="relative z-0 w-full pr-4 mb-5 group text-left">
            <label
              htmlFor="description"
              className="block text-sm text-gray-400"
            >
              Description
              <div className="flex flex-wrap gap-2 inset-0 text-gray-400 pointer-events-none p-3 whitespace-pre-wrap">
                <span>Ex: </span>
                <span className="text-[#557c70]">{placeholderText}</span>
              </div>
            </label>
            <Editor />
          </div>

          <div className="relative z-0 w-full pr-4 mb-5 group text-left">
            <label
              htmlFor="thankLetter"
              className="block text-sm text-gray-400"
            >
              Thank Letter
              <div className="flex flex-wrap gap-2 inset-0 text-gray-400 pointer-events-none p-3 whitespace-pre-wrap">
                <span>Ex: </span>
                <span className="text-[#557c70]">{placeholderTextThank}</span>
              </div>
            </label>
            <EditorThank />
          </div>

          <div className="w-full flex flex-wrap justify-between">
            <button
              type="button"
              onClick={() => {
                userFormStore.resetForm();
              }}
              className="w-[48%] text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="w-[48%] text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {userFormStore.typeOfForm?.toUpperCase()}
            </button>
          </div>
        </form>
      )}
    </div>
  );
});
