export const APIRoutes = {
  // Auth
  LOGIN: "/auth/signin",

  // User routes
  GET_USERS: "/user",
  GET_USER_DETAIL: (slug: string) => `/user/detail-admin/${slug}`,
  CREATE_USER: "/user",
  DELETE_USER: (id: string | number) => `/user/${id}`,
  UPDATE_USER: (id: string | number) => `/user/${id}`,

  // News routes
  GET_NEWS: "/news",
  GET_NEWS_DETAIL: (slug: string) => `/news/${slug}`,
  CREATE_NEWS: "/news",
  DELETE_NEWS: (id: string | number) => `/news/${id}`,
  UPDATE_NEWS: (id: string | number) => `/news/${id}`,

  // Static resource
  GET_IMAGES: "/static/list-files",
  DELETE_IMAGES: "/static",
  UPLOAD_SINGLE_IMAGE: "/static/upload",
};
