import { makeAutoObservable } from "mobx";
import { API, APIRoutes } from "../apis";

export interface IUser {
  id?: number;
  group?: string;
  name?: string;
  slug?: string;
  description?: string;
  thankLetter?: string;
  language?: string;
  location?: string;
  peopleConfirm?: number;
  createdDate?: string;
}

class UsersStore {
  users: IUser[] = [];
  $sizeOfPage: number = 100; // Default page size
  $currentPage: number = 1; // Default current page
  loadingUsers: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  // Set users data
  setUsers(users: IUser[]) {
    this.users = users;
  }

  // Fetch users from API
  async getUsers() {
    this.loadingUsers = true;
    try {
      const response = await API.get(APIRoutes.GET_USERS);
      this.setUsers(response.data); // Assign data to store
    } catch (error) {
      console.error("There was an error getting the users!", error);
    } finally {
      this.loadingUsers = false;
    }
  }

  // Get total count of users
  get lengthOfUsers() {
    return this.users.length;
  }

  // Get the size of pages
  get sizeOfPages() {
    return this.$sizeOfPage;
  }

  // Get the current page
  get currentPage() {
    return this.$currentPage;
  }

  // Set the page size
  setPageSize(size: number) {
    this.$sizeOfPage = size;
  }

  // Set the current page
  setCurrentPage(page: number) {
    this.$currentPage = page;
  }

  // Get paginated users
  get paginatedUsers() {
    const start = (this.currentPage - 1) * this.$sizeOfPage;
    const end = start + this.$sizeOfPage;
    return this.users.slice(start, end);
  }

  get totalPeople() {
    return this.users.reduce(
      (acc, user) => (acc += user.peopleConfirm || 0),
      0
    );
  }
}

export const usersStore = new UsersStore();
